import React from 'react';

import Defeated from '~/components/common/Defeated';
import Paragrah from '~/components/common/Paragrah';
import Section from '~/components/hero/LandingPage/Section';

const ErrorPage = ({ text }) => {
  return (
    <Section className="landing-page__section--hero">
      <Defeated />
      <Paragrah>{text}</Paragrah>
    </Section>
  );
};

export default ErrorPage;
