import type { NextPage } from 'next';

import ErrorPage from '~/components/hero/Error';
import MainNav from '~/components/navbar/MainNav';

const Home: NextPage = () => {
  return (
    <>
      <div className="container container--landing-page container--error-page">
        <header className="landing-page__header">
          <MainNav />
        </header>
        <div className="content-wrapper content-wrapper--full">
          <ErrorPage text={'Warrior & Page not found'} />
        </div>
      </div>
    </>
  );
};

export default Home;
